import React from 'react';
import { Container, Row, Col, Breadcrumb} from "react-bootstrap";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {Helmet} from "react-helmet";
import {Link} from 'gatsby'
import PageWrapper from "../components/PageWrapper";
import { Post } from "../components/Core";
import DataTable from '../components/data/banque/banque'
import DataTableMobile from '../components/data/banque/banqueMobile'
import Disclosure from '../components/Disclosure'
import {StaticImage} from 'gatsby-plugin-image';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartBar, faPercent, faCreditCard} from '@fortawesome/free-solid-svg-icons'
import { faBuilding} from '@fortawesome/free-regular-svg-icons'

function Accounting(props) {

	var d = new Date();
var month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
var n = month[d.getMonth()];
var y = d.getFullYear();

    return (
        <PageWrapper footerDark>
          <Helmet>
                <meta charSet="utf-8" />
                <title>Banque en ligne - Trouvez la meilleure banque en ligne</title>
                <meta name="description" content="Consulter le classement des banques en ligne française avec bankbank." />
            </Helmet>
          <Header/>
          <Container fluid style={{background: 'white', marginTop: 68, paddingTop: 20, paddingBottom: 130}}>
          <Container style={{maxWidth: 1280, marginTop: 28}}>
         
          <Breadcrumb>
            <Breadcrumb.Item> <Link to={`/`} style={{color: 'rgb(41, 49, 61)', fontWeight: 300}}>Accueil</Link></Breadcrumb.Item>
            <Breadcrumb.Item active style={{ color: 'rgb(189 190 191)', fontWeight: 300}}>Banque en ligne</Breadcrumb.Item>
          </Breadcrumb>
  

          <h1 id='compareh1' style={{color: 'rgb(41, 49, 61)'}}><span>Banque En Ligne</span> - Classement 2021</h1>
          <h2 id='compareh2' style={{marginLeft: 5, marginBottom: 25, color: 'rgb(41, 49, 61)'}}>Trouvez la meilleure banque en ligne avec un comparatif tarifs et cartes bancaires (mise à jour {n} {y})</h2>
          <div id='onetwothreemobile'>
          <p id='onep' style={{color: 'rgb(41, 49, 61)'}}><span id='one' style={{background: 'rgb(229 34 79)', paddingLeft: 10, paddingRight: 10, paddingTop:3, paddingBottom:3, borderRadius: 3, color: 'white'}}>1</span> - Utilisez le menu de notre <span style={{fontWeight: 700}}>comparateur banque en ligne</span> pour déterminer vos priorités : promo, carte gratuite, frais</p>
          <p id='twop' style={{color: 'rgb(41, 49, 61)'}}><span id='two' style={{background: 'rgb(229 34 79)', paddingLeft: 8, paddingRight: 9, paddingTop:3, paddingBottom:3, borderRadius: 3, color: 'white'}}>2</span> - Comparez les offres par tarif de carte bancaire, et les offres promos disponibles dès aujourd’hui</p>
          <p id='threep' style={{color: 'rgb(41, 49, 61)'}}><span id='three' style={{background: 'rgb(229 34 79)', paddingLeft: 8, paddingRight: 9, paddingTop:3, paddingBottom:3, borderRadius: 3, color: 'white'}}>3</span> - Sélectionnez la <span style={{fontWeight: 700}}>meilleure banque en ligne</span>, celle qui correspond le plus à vos besoins.</p>
          </div>

          <Disclosure/>
          <Row style={{height: 70, marginTop: -7, marginBottom: -50}}>
          
          </Row>
          </Container>
          </Container>  


     
          <Container style={{maxWidth: 1280, marginTop: -130}}>
        
          <Tabs style={{maxWidth: 1234}}>
    <TabList style={{marginLeft: 5}}>
      <Tab style={{marginLeft: 0}} id='tabWidth'><FontAwesomeIcon icon={faChartBar} style={{marginTop: 0, marginBottom: 0, fontSize: 20, marginLeft: 5, marginRight: 10}}/> Classement complet </Tab>
      <Tab style={{marginLeft: 0}} id='tabWidth'><FontAwesomeIcon icon={faBuilding} style={{marginTop: 0, marginBottom: -3, fontSize: 24, marginLeft: 5, marginRight: 10}}/> Sans condition de revenu</Tab>
      <Tab style={{marginLeft: 0}} id='tabWidth'><FontAwesomeIcon icon={faCreditCard} style={{marginTop: 0, marginBottom: -3, fontSize: 24, marginLeft: 5, marginRight: 10}}/> Cartes gratuites</Tab>
      <Tab style={{marginLeft: 0}} id='tabWidth'><FontAwesomeIcon icon={faPercent} style={{marginTop: 0, marginBottom: -3, fontSize: 21, marginLeft: 5, marginRight: 10}}/> Détails conditions promos</Tab>
    </TabList>

    <TabPanel>
    <DataTable row={[1,2,4,5,9,10,11,13, 14,15,16, 17,18,19,20,21,22,23,25,28,29,31,32,35,36,37]} what='comparatif' id='wideTable' classement='Note' order={true}/>
    <DataTableMobile row={[1,2,4,5,9,10,11,13, 14,15,16, 17,18,19,20,21,22,23,25,28,29,31,32,35,36,37]} what='comparatif' id='mobileTable' classement='Note' order={true}/>
    </TabPanel>
    <TabPanel>    
      <DataTable row={[1,2,4,5,9,10,11,13, 14,15,16, 17,18,19,20,21,22,23,25,28,29,31,32,35,36,37]} what='sanscondition' id='wideTable' classement='Note' order={true}/>
      <DataTableMobile row={[1,2,4,5,9,10,11,13, 14,15,16, 17,18,19,20,21,22,23,25,28,29,31,32,35,36,37]} what='sanscondition' id='mobileTable' classement='Note' order={true}/>
    </TabPanel>
      <TabPanel>      
      <DataTable row={[12,4,18,9,19,30,20]} what='cartesgratuites' id='wideTable' classement='Note' order={true}/>
      <DataTableMobile row={[12,4,18,9,19,30,20]} what='cartesgratuites' id='mobileTable' classement='Note' order={true}/>
    </TabPanel>
    <TabPanel>
      <DataTable row={[1,2,4,5,9,10,11,13, 14,15,16, 17,18,19,20,21,22,23,25,28,29,31,32,35,36,37]} what='conditions' id='wideTable' classement='Note' order={true}/>
      <DataTableMobile row={[1,2,4,5,9,10,11,13, 14,15,16, 17,18,19,20,21,22,23,25,28,29,31,32,35,36,37]} what='conditions' id='mobileTable' classement='Note' order={true}/>
    </TabPanel>  
  

  

  </Tabs>
            <Row className="justify-content-center">
              <Col>
            
              {/* <Disclosure/> */}
            

       
              </Col>
            </Row>
             
          </Container>
          <Container style={{marginTop: 100, marginBottom : 100}}>
          <Post>
    
      
          <h2>Comment trouver la meilleure banque en ligne ?</h2>
<p>La meilleure banque en ligne sera différente pour tout le monde. Selon votre profil, le choix d’une banque peut être basé sur les frais bancaires, l’envie de bénéficier d’une offre promo, la différence entre les cartes bancaires et évidemment les services offerts par la banque.
</p>

<StaticImage
		  	src='../images/banque-en-ligne.png' width={1200}
			alt='meilleure banque en ligne'
			placeholder='tracedSVG'
			/>

<p>
Le comparateur BankBank tente de répondre à ces besoins avec 4 tableaux comparatifs.
</p>
<h3>
Comparatif complet</h3>
<p>
La première comparaison est un classement des banques en un coup d’oeil. Ce comparatif vous permettra de choisir une banque très rapidement grâce au scoring BankBank qui prend en compte:
</p> <ul>
  <li>les offres promo du moment</li>
  <li>la gratuité des cartes bancaires proposés</li>
  <li>une sélection de coûts bancaires</li>
</ul>

<h3>
Offres promos des banques en ligne</h3>
<p>
Ce tableau entre dans le détail des bonus offerts par les banques en ligne. La concurrence a été favorable aux consommateurs. Tout au long de l’année vous allez accéder à des offres allant de frais de tenue de compte réduit à des véritables bonus en argent versé directement sur votre compte bancaire. Ces offres varient très régulièrement. Nous nous assurons donc d’indiquer la date de fin de l’offre ainsi que les nouvelles conditions à chaque mise à jour.
</p>
<DataTable row={[35,15,2,16,36]} what='conditions' id='postTable'/>
    <DataTable row={[35,15,2,16,36]} what='conditions' id='mobileTable'/>

<h3>
Cartes bancaires gratuites</h3>
<p>
Votre premier choix sera le réseau de carte bancaire associé à votre banque: Visa ou Mastercard ? Puis, ce seront vos revenus et leur domiciliation qui vont déterminer le niveau de gratuité de votre carte bancaire. Il peut parfois être intéressant de payer un supplément pour avoir une meilleure carte bancaire et accéder gratuitement à des services qui seraient normalement payant. On pense notamment à l’assurance et les frais à l’étranger qui sont souvent impactés par votre niveau de carte.
</p>
<DataTable row={[12,4,18,9,19,30,20]} what='cartesgratuites' id='postTable'/>
      <DataTable row={[12,4,18,9,19,30,20]} what='cartesgratuites' id='mobileTable'/>
 
<h3>
Banque en ligne – le comparatif frais de cartes</h3>
<p>
Si votre carte bancaire peut être gratuite, son utilisation peut malgré tout être payante. Et ce coût peut prendre des niveaux assez vertigineux lors de vos déplacements à l’étranger. Attention de ne pas tout faire pour économiser 20€ sur votre abonnement et perdre 300€ en frais bancaire durant vos vacances d’été.
</p>
<DataTable row={[9,21,4,31]} what='fraisbancaires' id='postTable'/>
<DataTable row={[9,21,4,31]} what='fraisbancaires' id='mobileTable'/>

<h3>
Meilleure banque en ligne à l’étranger</h3>

<h4>
Retraits et paiements à l’étranger</h4>
<DataTable row={[9,21,4,31]} what='retraits' id='postTable'/>
<DataTable row={[9,21,4,31]} what='retraits' id='mobileTable'/>
<h3>
Assurances</h3>
<DataTable row={[9,21,4,31]} what='assurances' id='postTable'/>
<DataTable row={[9,21,4,31]} what='assurances' id='mobileTable'/>






</Post>

          </Container>
          <Footer/>
      </PageWrapper>
    )
}

export default Accounting
